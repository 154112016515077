import { nanoid } from "nanoid";

import { toImage, toPage, toResponsiveImage } from "../adapter";
import { toMatchDetails } from "../match/adapter";

const toSponsors = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        name: n.name,
        url: n.url,
        tier: n.tier,
        image: n.logo && n.logo.imageUrl,
      }))
    : null;

const toTeam = (data) =>
  data
    ? {
        ...toPage(data),
        team: {
          nationalTeamId: data.nationalTeamId,
          id: data.teamId,
          name: data.title,
          ageCategory: data.ageCategory,
          gender: data.gender,
          discipline: data.discipline,
          logoUrl: data.logoImageUrl,
          themeColor1: data.themeColorFirst,
          themeColor2: data.themeColorSecond,
          images:
            data.bannerImage &&
            data.bannerImage.length &&
            toResponsiveImage(data.bannerImage[0].images),
          competitions: data.competitions,
          defaultCompetitionId: data.defaultCompetitionTypeId,
          sponsors: toSponsors(data.sponsors),
          isHidden: data.isHidden,
        },
      }
    : null;

export const toTeamDetails = (data) => toTeam(data);

export const toTeams = (data) => (data ? data.map((n) => toTeam(n)) : []);

const toNewsCategory = (data) =>
  data
    ? {
        id: data.id,
        title: data.title,
        url: data.url,
      }
    : null;

export const toTeamNews = (data) =>
  data
    ? data.map((n) => ({
        ...toPage(n),
        summary: n.summary,
        tags: n.tags && n.tags.filter((m) => m),
        date: n.date,
        isFeatured: n.isFeatured,
        category: toNewsCategory(n.category),
        video: n.video,
      }))
    : [];

export const toPlayers = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        name: n.name,
        number: n.number,
        position: n.position,
        profilePhotoUrl: n.profilePhoto,
        isGoalkeeper: n.isGoalkeeper,
        isCaptain: n.isCaptain,
      }))
    : [];

export const toNextPage = ({ page, pageCount }) => {
  const pageCountAsInt = parseInt(pageCount, 10);
  if (
    pageCountAsInt &&
    !Number.isNaN(pageCountAsInt) &&
    pageCountAsInt > 1 &&
    page < pageCountAsInt
  ) {
    return page + 1;
  }

  return null;
};

export const toMatches = (data) =>
  data ? data.map((n) => toMatchDetails(n)) : [];

export const toTopScorers = (data) =>
  data
    ? data.map((n) => ({
        id: n.player.id,
        name: n.player.name,
        profilePhotoUrl: n.player.profilePhoto,
        mp: n.matchesPlayed,
        goals: n.goals,
      }))
    : null;

export const toAppearances = (data) =>
  data
    ? data.map((n) => ({
        id: n.player.id,
        name: n.player.name,
        dob: n.player.dob,
        debut: n.player.debut,
        debutDate: n.player.debutDate,
        profilePhotoUrl: n.player.profilePhoto,
        mp: n.matchesPlayed,
      }))
    : null;

export const toCoaches = (data) =>
  data
    ? data.map((n) => ({
        id: nanoid(),
        name: n.name,
        profilePhoto: toImage(n.profilePhoto),
        from: n.from,
        to: n.to,
      }))
    : null;

export const toCompetitions = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        title: n.title,
      }))
    : null;

export const toClub = (data) =>
  data
    ? {
        id: data.id,
        name: data.name,
        logoUrl: data.image,
      }
    : null;

export const toTable = (data) =>
  data
    ? data.map((n) => ({
        id: n.position,
        teamId: n.club.id,
        name: n.club.name,
        logoUrl: n.club.image,
        pos: n.position,
        mp: n.matchesPlayed,
        w: n.wins,
        d: n.draws,
        l: n.losses,
        gd: n.goalDifference,
        pts: n.points,
        next: toClub(n.nextMatch),
        isLive: n.nextMatch && n.nextMatch.isLive,
        change: n.change,
      }))
    : null;
