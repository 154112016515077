import * as React from "react";

import CompetitionThemedHeading from "../../components/competitionThemedHeading";
import ErrorBoundary from "../../components/errorBoundary";
import usePoll from "../../hooks/poll";
import styles from "./style.scss";

const POLL_INTERVAL = 60000;

const LABELS = {
  TITLE: "Standings",
  POS: "",
  CLUB: "",
  MP: "MP",
  GD: "GD",
  PTS: "PTS",
};

const NationalTeamCompetitionTableSummary = ({
  title,
  competitionId,
  teamId,
  onGetCompetitionTable,
  isReady,
  isError,
  table,
  isCurrent,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetCompetitionTable({ competitionId });
    }
  }, [competitionId, onGetCompetitionTable, isReady]);

  const handleGetCompetitionTable = React.useCallback(
    () => onGetCompetitionTable({ competitionId }),
    [onGetCompetitionTable, competitionId]
  );

  usePoll({
    callback: handleGetCompetitionTable,
    interval: isCurrent ? POLL_INTERVAL : 0,
  });

  if (isError) {
    return null;
  }

  if (!isReady) {
    return (
      <section>
        <CompetitionThemedHeading>
          <h2 className={styles.title}>{title}</h2>
        </CompetitionThemedHeading>
        <ul className={`${styles.list}`}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <span className={`${styles.column} ${styles.columnPos}`}>
              {LABELS.POS}
            </span>
            <span className={`${styles.column} ${styles.columnTeam}`}>
              {LABELS.CLUB}
            </span>
            <span className={styles.column}>{LABELS.MP}</span>
            <span className={styles.column}>{LABELS.GD}</span>
            <span className={styles.column}>{LABELS.PTS}</span>
          </li>
        </ul>
        <ul className={`${styles.list} ${styles.listData}`}>
          {[...Array(6)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  if (!(table && table.length)) {
    return null;
  }

  return (
    <section>
      <CompetitionThemedHeading>
        <h2 className={styles.title}>{title}</h2>
      </CompetitionThemedHeading>
      <ErrorBoundary>
        <ul className={styles.list}>
          <li className={`${styles.listItem} ${styles.listItemHeading}`}>
            <span className={`${styles.column} ${styles.columnPos}`}>
              {LABELS.POS}
            </span>
            <span className={`${styles.column} ${styles.columnTeam}`}>
              {LABELS.CLUB}
            </span>
            <span className={styles.column}>{LABELS.MP}</span>
            <span className={styles.column}>{LABELS.GD}</span>
            <span className={styles.column}>{LABELS.PTS}</span>
          </li>
        </ul>
        <ul className={`${styles.list} ${styles.listData}`}>
          {table.map((n) => (
            <li
              key={n.id}
              className={`${styles.listItem} ${
                teamId === n.teamId ? styles.listItemSelected : ""
              }`}
            >
              <span className={`${styles.column} ${styles.columnPos}`}>
                {n.pos}
              </span>
              <div
                className={`${styles.column} ${styles.columnTeam} ${
                  n.isLive && n.change === 0 ? styles.isLive : ""
                } ${n.isLive && n.change > 0 ? styles.up : ""}  ${
                  n.isLive && n.change < 0 ? styles.down : ""
                }`}
              >
                <span className={styles.columnImgWrapper}>
                  <img
                    src={n.logoUrl}
                    alt=""
                    className={styles.columnImg}
                    loading="lazy"
                  />
                </span>
                <span className={styles.columnName}>{n.name}</span>
              </div>
              <span className={styles.column}>{n.mp}</span>
              <span className={styles.column}>{n.gd}</span>
              <span className={`${styles.column} ${styles.columnPts}`}>
                {n.pts}
              </span>
            </li>
          ))}
        </ul>
      </ErrorBoundary>
    </section>
  );
};

export default NationalTeamCompetitionTableSummary;
