import Api from "../../../../service";
import {
  RESET_COMPETITIONMATCHES,
  SET_COMPETITIONMATCHES,
  SET_ERROR,
  getKey,
} from "./constants";

const setCompetitionMatches = (data, key) => ({
  type: SET_COMPETITIONMATCHES,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

export const getCompetitionMatches = (data) => (dispatch) =>
  Api.competition
    .getMatches(data)
    .then((response) => {
      dispatch(setCompetitionMatches(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error, getKey(data)));
    });

export const resetCompetitionMatches = (data) => ({
  type: RESET_COMPETITIONMATCHES,
  data,
  key: getKey(data),
});
