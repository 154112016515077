import * as React from "react";

const CompetitionGameWeeks = ({
  isReady,
  competitionId,
  season,
  onGetCompetitionGameWeeks,
  children,
  maxGameWeek,
}) => {
  React.useEffect(() => {
    onGetCompetitionGameWeeks({ competitionId, season });
  }, [season, competitionId, onGetCompetitionGameWeeks]);

  return <>{children({ isReady, maxGameWeek })}</>;
};

export default CompetitionGameWeeks;
