export const REDUCER_NAME = "nationalTeamMatches";
export const SET_ERROR = `${REDUCER_NAME}/SET_ERROR`;
export const SET_TEAMMATCHES = `${REDUCER_NAME}/SET_TEAMMATCHES`;
export const RESET_TEAMMATCHES = `${REDUCER_NAME}/RESET_TEAMMATCHES`;

export const TYPE = {
  LIVE: "live",
  UPCOMING: "upcoming",
  PAST: "past",
};

export const getKey = (data) => `${data.nationalTeamId}_${data.type}`;
