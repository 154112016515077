import * as React from "react";
import { useLocation, useParams } from "react-router-dom";

import { TYPE } from "../../../../service/competition";
import CompetitionGameWeeks from "../../containers/competitionGameWeeks";
import CompetitionSeasons from "../../containers/competitionSeasons";
import CompetitionTable from "../../containers/competitionTable";
import TeamMatches from "../../containers/teamMatches";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
import styles from "./critical.scss";

const LABELS = {
  TITLE_PAST: "Past Fixtures",
};

const PAGINATION_QUERY_STRING_PARAM_NAME = "gameWeek";

const TeamPastSeasonsPage = ({
  teamId,
  competitionId,
  currentSeason,
  gameWeek: currentGameWeek,
}) => {
  const { season } = useParams();
  const location = useLocation();

  return (
    <CompetitionGameWeeks
      competitionId={competitionId}
      season={season || currentSeason}
    >
      {({ isReady, maxGameWeek }) => {
        const searchParams = new URLSearchParams(location.search);
        let gameWeekParam = searchParams.get(
          PAGINATION_QUERY_STRING_PARAM_NAME
        );

        if (gameWeekParam && !Number.isNaN(gameWeekParam)) {
          gameWeekParam = Number(gameWeekParam);
        } else {
          gameWeekParam =
            season === currentSeason ? currentGameWeek - 1 : maxGameWeek;
        }

        return (
          <>
            <section className={styles.section}>
              <CompetitionSeasons
                teamId={teamId}
                competitionId={competitionId}
                season={season || currentSeason}
              />
            </section>
            <section className={styles.section}>
              <CompetitionTable
                teamId={teamId}
                competitionId={competitionId}
                season={season || currentSeason}
                showAchievements={Boolean(
                  (season && season !== currentSeason) ||
                    (currentSeason && currentGameWeek === -1)
                )}
              />
              <LeaderboardOrSquareAdvertisement
                uid="competition-archive-page-1"
                competitionId={competitionId}
              />
            </section>
            {season && season !== currentSeason && Boolean(maxGameWeek) && (
              <section>
                <TeamMatches
                  title={LABELS.TITLE_PAST}
                  teamId={teamId}
                  competitionId={competitionId}
                  type={TYPE.PAST}
                  listClassName={styles.results}
                  showPagination
                  paginationQueryStringParamName={
                    PAGINATION_QUERY_STRING_PARAM_NAME
                  }
                  maxGameWeek={maxGameWeek}
                  gameWeek={Number(gameWeekParam)}
                  season={season || currentSeason}
                  waitForParentReady={!isReady}
                />
              </section>
            )}
          </>
        );
      }}
    </CompetitionGameWeeks>
  );
};

export default TeamPastSeasonsPage;
