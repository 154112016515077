import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import DatePicker from "react-datepicker";

import CompetitionMatches from "../competitionMatches";
import { TYPE } from "../competitionMatches/constants";
import styles from "./style.scss";

const LABELS = {
  LOAD_MORE: "More matches",
  COMPETITION_TITLE: "Filter by Competition",
  DATE_TITLE: "Filter by date",
  NO_RESULTS: "No matches matched your search criteria.",
};

const DEFAULT_COMPETITION = 58539;

export const PastMatchesSection = ({
  isReady,
  isError,
  competitions,
  title,
  onGetCompetitions,
  active,
  className,
  showPagination,
}) => {
  const [competitionId, setCompetitionId] = useState(DEFAULT_COMPETITION);
  const [date, setDate] = useState(null);

  const pageSize = useMemo(() => {
    const competition = competitions.find(
      (n) => `${n.id}` === `${competitionId}`
    );
    if (competition) {
      return competition.matchPageSize;
    }

    return null;
  }, [competitionId, competitions]);

  React.useEffect(() => {
    if (!isReady) {
      onGetCompetitions();
    }
  }, [onGetCompetitions, isReady]);

  if (isError) {
    return null;
  }

  if (!isReady) {
    return null;
  }

  return (
    <section important="true" className={className || styles.section}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.filters}>
        <div className={styles.filter}>
          <label htmlFor="competition" className={styles.filterTitle}>
            {LABELS.COMPETITION_TITLE}
          </label>
          {Boolean(competitions && competitions.length) && (
            <select
              id="competition"
              className={styles.list}
              defaultValue={DEFAULT_COMPETITION}
              onChange={(e) => setCompetitionId(e.target.value)}
            >
              {competitions.map((n) => (
                <option
                  className={`${styles.listItem} ${
                    n.id === active ? styles.listItemActive : ""
                  }`}
                  key={n.id}
                  value={n.id}
                >
                  {n.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className={styles.filter}>
          <label htmlFor="date" className={styles.filterTitle}>
            {LABELS.DATE_TITLE}
          </label>
          <input
            id="date"
            type="date"
            className={`${styles.date} ${styles.dateMobile}`}
            value={date ? format(date, "yyyy-MM-dd") : ""}
            onChange={(n) => setDate(n.target.valueAsDate)}
            max={format(new Date(), "yyyy-MM-dd")}
          />
          <DatePicker
            className={`${styles.date} ${styles.dateDesktop}`}
            selected={date}
            onChange={(n) => setDate(n)}
            maxDate={new Date()}
            dateFormat="dd MMM yyyy"
          />
        </div>
      </div>
      <CompetitionMatches
        competitionId={competitionId}
        type={TYPE.PAST}
        noResultsMessage={LABELS.NO_RESULTS}
        date={date}
        showPagination={showPagination}
        pageSize={pageSize}
      />
    </section>
  );
};
