import {
  toClubs,
  toCompetitionDetails,
  toCompetitionNews,
  toCompetitionPage,
  toCompetitions,
  toCup,
  toGameWeeks,
  toHighlights,
  toMatches,
  toNextPage,
  toSeasons,
  toSponsors,
  toStandings,
  toTopScorers,
} from "./adapter";
import DataService from "./dataService";
import HighlightsService from "./highlightsService";
import NewsService from "./newsService";
import PageService from "./pageService";

export const TYPE = {
  LIVE: "live",
  UPCOMING: "upcoming",
  PAST: "past",
};

const MATCHES_PAGE_SIZE = 8;
const UPCOMING_PAGE_SIZE = 8;
const TOP_SCORERS_PAGE_SIZE = 20;

export default class {
  constructor({
    pageService,
    dataService,
    newsService,
    highlightsService,
  } = {}) {
    this.pageService = pageService || new PageService();
    this.dataService = dataService || new DataService();
    this.newsService = newsService || new NewsService();
    this.highlightsService = highlightsService || new HighlightsService();
  }

  getPage(data) {
    return this.pageService
      .getPage(data)
      .then((page) => {
        const { competitionId } = page.params;
        return this.getCompetitionDetails({ id: Number(competitionId) })
          .then((competition) => toCompetitionPage({ page, competition }))
          .catch((error) => {
            console.error(error);
            throw error;
          });
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getCompetitionDetails(data) {
    return this.dataService
      .getCompetitionDetails(data)
      .then((response) => toCompetitionDetails(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getAll(data) {
    return this.dataService
      .getAll(data)
      .then((response) => toCompetitions(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getMatches(data) {
    const { type, page, pageSize } = data;

    if (type === TYPE.UPCOMING) {
      return this.dataService
        .getUpcomingMatches({
          ...data,
          pageSize: pageSize || UPCOMING_PAGE_SIZE,
        })
        .then((res) => {
          if (res.ok) {
            return res
              .json()
              .then((response) => ({
                nextPage: toNextPage({
                  page: page || 1,
                  pageCount: res.headers.get("PageCount"),
                }),
                matches: toMatches(response),
              }))
              .catch((error) => {
                console.error(error);
                throw error;
              });
          }
          throw new Error(res.statusText);
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    }

    if (type === TYPE.PAST) {
      return this.dataService
        .getPastMatches({
          ...data,
          pageSize: pageSize || MATCHES_PAGE_SIZE,
        })
        .then((res) => {
          if (res.ok) {
            return res
              .json()
              .then((response) => ({
                nextPage: toNextPage({
                  page: page || 1,
                  pageCount: res.headers.get("PageCount"),
                }),
                matches: toMatches(response),
              }))
              .catch((error) => {
                console.error(error);
                throw error;
              });
          }
          throw new Error(res.statusText);
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    }

    return this.dataService
      .getMatches({
        ...data,
        pageSize: pageSize || MATCHES_PAGE_SIZE,
      })
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then((response) => ({
              nextPage: toNextPage({
                page: page || 1,
                pageCount: res.headers.get("PageCount"),
              }),
              matches: toMatches(response),
            }))
            .catch((error) => {
              console.error(error);
              throw error;
            });
        }
        throw new Error(res.statusText);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getCompetitionNews({ competitionId, ...data }) {
    return this.newsService
      .getCompetitionNews({ id: competitionId, ...data })
      .then((response) => toCompetitionNews(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getCompetitionHighlights(data) {
    return this.highlightsService
      .getHighlights(data)
      .then((response) => toHighlights(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getClubs(data) {
    return this.dataService
      .getClubs(data)
      .then((response) => toClubs(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getCompetitionTable(data) {
    return this.dataService
      .getTable(data)
      .then((response) => toStandings(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getCompetitionCup(data) {
    return this.dataService
      .getCup(data)
      .then((response) => toCup(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getCompetitionTopScorers(data) {
    return this.dataService
      .getTopScorers({
        ...data,
        id: data.competitionId,
        pageSize: TOP_SCORERS_PAGE_SIZE,
      })
      .then((response) =>
        response
          ? response.map((n) => ({
              id: n.competitionTypeId,
              name: n.competitionName,
              competitionType: n.competitionTypeName,
              data: toTopScorers(n.data),
            }))
          : []
      )
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getCompetitionSponsors(data) {
    return this.dataService
      .getSponsors(data)
      .then((response) => toSponsors(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getCompetitionSeasons(data) {
    return this.dataService
      .getSeasons(data)
      .then((response) => toSeasons(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getCompetitionGameWeeks(data) {
    const { competitionId, season } = data;

    return this.dataService
      .getGameWeeks({ id: Number(competitionId), season })
      .then((response) => toGameWeeks(response))
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
