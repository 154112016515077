import * as React from "react";
import { Link } from "react-router-dom";

import { TYPE } from "../../../../service/competition";
import ErrorBoundary from "../../components/errorBoundary";
import Scoreboard from "../../components/scoreboard";
import ThemedHeading from "../../components/themedHeading";
import usePoll from "../../hooks/poll";
import CompetitionMatchChannel from "../competitionMatchChannel";
import MatchEvents from "../matchEvents";
import styles from "./style.scss";

const URL_TEMPLATE = "/match/:matchId";
const PLACEHOLDER = ":matchId";

const LABELS = {
  DETAILS: "Details",
  SEPARATOR: "vs",
  UNKNOWN_VENUE: "Venue: TBC",
  POSTPONED: "Post.",
};

const POLL_INTERVAL = 60000;
const POLL_INTERVAL_LIVE = 12000;

const Component = ({
  title,
  nationalTeamId,
  onGetNationalTeamMatches,
  onResetNationalTeamMatches,
  isReady,
  matches,
  type,
  subtitle,
  subtitleColor,
  pageSize,
  isWidget,
}) => {
  React.useEffect(() => {
    if (!isReady) {
      onGetNationalTeamMatches({ nationalTeamId, type, pageSize });
    }
  }, [
    nationalTeamId,
    type,
    onGetNationalTeamMatches,
    onResetNationalTeamMatches,
    isReady,
    pageSize,
  ]);

  React.useEffect(() => {
    return () => {
      if (type === TYPE.PAST) {
        onResetNationalTeamMatches({ nationalTeamId, type });
      }
    };
  }, [onResetNationalTeamMatches, nationalTeamId, type]);

  const handleGetNationalTeamMatches = React.useCallback(
    () => onGetNationalTeamMatches({ nationalTeamId, type, pageSize }),
    [nationalTeamId, type, onGetNationalTeamMatches, pageSize]
  );

  usePoll({
    callback: handleGetNationalTeamMatches,
    interval: type === TYPE.LIVE ? POLL_INTERVAL_LIVE : POLL_INTERVAL,
  });

  if (!isReady && !(type === TYPE.LIVE)) {
    return (
      <section className={title ? styles.section : styles.container}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <ul className={`${styles.list}`}>
          {[...Array(pageSize)].map((n, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${styles.listItem} ${styles.skeleton}`}
            />
          ))}
        </ul>
      </section>
    );
  }

  if (!(matches && matches.length)) {
    return null;
  }

  const linkContent = (n) => (
    <>
      {n.isLive ? (
        <div className={styles.matchTime}>
          <span className={styles.matchTimeValue}>
            {n.matchTime && n.matchTime.startsWith("-") ? "-" : n.matchTime}
          </span>
        </div>
      ) : (
        <span className={styles.startDate}>
          {n.isPostponed ? LABELS.POSTPONED : n.formattedStartDate}
        </span>
      )}
      <div className={styles.details}>
        <div className={styles.detailsVenue}>
          {subtitle && (
            <ThemedHeading color={subtitleColor}>
              <span className={styles.subtitle}>{subtitle}</span>
            </ThemedHeading>
          )}
          <span className={styles.detailsVenueName}>
            {n.venue ? n.venue.name : LABELS.UNKNOWN_VENUE}
          </span>
        </div>
        <div className={styles.teams}>
          <div className={`${styles.team} ${styles.teamHome}`}>
            <span className={`${styles.teamName} ${styles.teamNameHome}`}>
              {n.homeTeam.name}
            </span>
            <img
              src={n.homeTeam.logoUrl}
              alt=""
              className={styles.teamImg}
              loading="lazy"
            />
          </div>
          <div className={styles.teamsSeparator}>
            {n.isEnded || n.isLive ? (
              <MatchEvents matchId={n.id} isLive={n.isLive}>
                {({ score, isReady: isScoreboardReady }) => (
                  <Scoreboard score={score} isReady={isScoreboardReady} />
                )}
              </MatchEvents>
            ) : (
              LABELS.SEPARATOR
            )}
          </div>
          <div className={`${styles.team} ${styles.teamAway}`}>
            <img
              src={n.awayTeam.logoUrl}
              alt=""
              className={styles.teamImg}
              loading="lazy"
            />
            <span className={`${styles.teamName} ${styles.teamNameAway}`}>
              {n.awayTeam.name}
            </span>
          </div>
        </div>
      </div>
      {!n.isEnded ? <CompetitionMatchChannel id={n.id} /> : <div />}
      <span className={styles.link}>{LABELS.DETAILS}</span>
    </>
  );

  return (
    <section className={title ? styles.section : styles.container}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <ErrorBoundary>
        <ul className={styles.list}>
          {matches.map((n) => (
            <li className={styles.listItem} key={n.id}>
              {isWidget ? (
                <a
                  href={URL_TEMPLATE.replace(PLACEHOLDER, n.id)}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.listItemContent}
                >
                  {linkContent(n)}
                </a>
              ) : (
                <Link
                  to={URL_TEMPLATE.replace(PLACEHOLDER, n.id)}
                  className={styles.listItemContent}
                >
                  {linkContent(n)}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </ErrorBoundary>
    </section>
  );
};

export default Component;
