import * as React from "react";
import { Route, Switch } from "react-router-dom";

import { CompetitionArchivePage } from "../../components/competition/competitionArchivePage";
import { CompetitionCupPage } from "../../components/competition/competitionCupPage";
import { CompetitionHomePage } from "../../components/competition/competitionHomePage";
import { CompetitionNewsPage } from "../../components/competition/competitionNewsPage";
import { CompetitionTablePage } from "../../components/competition/competitionTablePage";
import { CompetitionTopScorersPage } from "../../components/competition/competitionTopScorersPage";
import CompetitionClubs from "../competitionClubs";
import { ROUTES } from "./constants";

export const Routes = React.memo(({ path, competition }) => {
  return (
    <Switch>
      <Route path={`${path}${ROUTES.NEWS}`}>
        <CompetitionNewsPage competitionId={competition.id} />
      </Route>
      <Route path={`${path}${ROUTES.CLUBS}`}>
        <CompetitionClubs competitionId={competition.id} />
      </Route>
      <Route path={`${path}${ROUTES.CUP}`}>
        <CompetitionCupPage
          competitionId={competition.id}
          season={competition.season}
        />
      </Route>
      <Route path={`${path}${ROUTES.TABLE}`}>
        <CompetitionTablePage
          competitionId={competition.id}
          season={competition.season}
        />
      </Route>
      <Route path={`${path}${ROUTES.TOP_SCORERS}`}>
        <CompetitionTopScorersPage competitionId={competition.id} />
      </Route>
      <Route path={`${path}${ROUTES.ARCHIVE}`}>
        <CompetitionArchivePage
          competitionId={competition.id}
          currentSeason={competition.season}
          gameWeek={competition.gameWeek}
          isCup={competition.isCup}
        />
      </Route>
      <Route path={`${path}${ROUTES.HOME}`}>
        <CompetitionHomePage
          isCup={competition.isCup}
          competitionId={competition.id}
          gameWeek={competition.gameWeek}
          matchPageSize={competition.matchPageSize}
        />
      </Route>
    </Switch>
  );
});
