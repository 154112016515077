import Api from "../../../../service";
import {
  RESET_TEAMMATCHES,
  SET_ERROR,
  SET_TEAMMATCHES,
  getKey,
} from "./constants";

const setTeamMatches = (data, key) => ({
  type: SET_TEAMMATCHES,
  data,
  key,
});

const setError = (data, key) => ({
  type: SET_ERROR,
  data,
  key,
});

export const getNationalTeamMatches = (data) => (dispatch) =>
  Api.nationalTeam
    .getMatches(data)
    .then((response) => {
      dispatch(setTeamMatches(response, getKey(data)));
      return response;
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(error, getKey(data)));
    });

export const resetNationalTeamMatches = (data) => ({
  type: RESET_TEAMMATCHES,
  data,
  key: getKey(data),
});
