import * as React from "react";
import { useLocation, useParams } from "react-router-dom";

import { TYPE } from "../../../../service/competition";
import CompetitionGameWeeks from "../../containers/competitionGameWeeks";
import CompetitionResults from "../../containers/competitionResults";
import CompetitionSeasons from "../../containers/competitionSeasons";
import CompetitionTable from "../../containers/competitionTable";
import CompetitionTopScorers from "../../containers/competitionTopScorers";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./critical.scss";

const PAGINATION_QUERY_STRING_PARAM_NAME = "gameWeek";

const LABELS = {
  TITLE_PAST: "Past Fixtures",
};

export const CompetitionArchivePage = ({
  isCup,
  competitionId,
  currentSeason,
  gameWeek: currentGameWeek,
}) => {
  const { season } = useParams();
  const location = useLocation();

  return (
    <CompetitionGameWeeks
      competitionId={competitionId}
      season={season || currentSeason}
    >
      {({ isReady, maxGameWeek }) => {
        const searchParams = new URLSearchParams(location.search);
        let gameWeekParam = searchParams.get(
          PAGINATION_QUERY_STRING_PARAM_NAME
        );

        if (gameWeekParam && !Number.isNaN(gameWeekParam)) {
          gameWeekParam = Number(gameWeekParam);
        } else {
          gameWeekParam =
            season === currentSeason ? currentGameWeek - 1 : maxGameWeek;
        }

        return (
          <>
            <section className={styles.section}>
              <CompetitionSeasons
                competitionId={competitionId}
                season={season || currentSeason}
              />
            </section>
            {!isCup && (
              <section className={styles.section}>
                <CompetitionTable
                  competitionId={competitionId}
                  season={season || currentSeason}
                />
                <LeaderboardOrSquareAdvertisement
                  uid="competition-archive-page-1"
                  competitionId={competitionId}
                />
              </section>
            )}
            <section className={styles.section}>
              <CompetitionTopScorers
                competitionId={competitionId}
                season={season || currentSeason}
              />
            </section>
            {season && season !== currentSeason && Boolean(maxGameWeek) && (
              <section className={styles.section}>
                <CompetitionResults
                  title={LABELS.TITLE_PAST}
                  competitionId={competitionId}
                  type={TYPE.PAST}
                  listClassName={styles.results}
                  showPagination
                  paginationQueryStringParamName={
                    PAGINATION_QUERY_STRING_PARAM_NAME
                  }
                  maxGameWeek={maxGameWeek}
                  gameWeek={Number(gameWeekParam)}
                  season={season || currentSeason}
                  waitForParentReady={!isReady}
                />
              </section>
            )}
          </>
        );
      }}
    </CompetitionGameWeeks>
  );
};
