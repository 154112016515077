import * as React from "react";
import { Link, useLocation } from "react-router-dom";

import CompetitionThemedBackground from "../competitionThemedBackground";
import styles from "./style.scss";

const getPaginationUrl = (location, variableName, page) => {
  if (typeof URLSearchParams !== "undefined") {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(variableName, page);
    return `${location.pathname}?${searchParams.toString()}`;
  }
  return null;
};

const CompetitionResultsPagination = ({
  page,
  label,
  className,
  variableName,
  maxPage,
}) => {
  const location = useLocation();

  if (maxPage === 1) {
    return null;
  }

  return (
    <div className={`${className ?? ""}`}>
      <Link
        to={getPaginationUrl(location, variableName, page - 1)}
        className={`${styles.previous} ${page === 1 ? styles.disabled : ""}`}
      >
        <CompetitionThemedBackground />
      </Link>
      <span className={styles.label}>{label}</span>
      <Link
        to={getPaginationUrl(location, variableName, page + 1)}
        className={`${styles.next} ${page === maxPage ? styles.disabled : ""}`}
      >
        <CompetitionThemedBackground />
      </Link>
    </div>
  );
};

export default CompetitionResultsPagination;
