import * as React from "react";

import CompetitionMatches from "../../containers/competitionMatches";
import { TYPE } from "../../containers/competitionMatches/constants";
import Competitions from "../../containers/competitions";
import CompetitionTableSummary from "../../containers/competitionTableSummary";
import HomeHighlights from "../../containers/homeHighlightsSection";
import HomeNewsSection from "../../containers/homeNewsSection";
import NationalTeamMatches from "../../containers/nationalTeamMatches";
import NationalTeams from "../../containers/nationalTeams";
import PastMatchesSection from "../../containers/pastMatchesSection";
import UpcomingMatchesSection from "../../containers/upcomingMatchesSection";
import BannerAdvertisement from "../bannerAdvertisement";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
import styles from "./critical.scss";

const LABELS = {
  TITLE_NEWS: "LATEST NEWS",
  TITLE_HIGHLIGHTS: "HIGHLIGHTS",
  TITLE_UPCOMING: "UPCOMING MATCHES",
  TITLE_PAST: "LATEST RESULTS",
  TITLE_STANDINGS: "BOV Premier League",
  TITLE_NATIONAL_TEAM_PAST: "NATIONAL TEAM RESULTS",
};

const HomePage = ({ competitionId }) => (
  <div className={styles.container}>
    <section className={styles.content}>
      <NationalTeams>
        {(nationalTeams) =>
          nationalTeams.map(({ name, themeColor1, nationalTeamId }) => (
            <NationalTeamMatches
              key={nationalTeamId}
              nationalTeamId={nationalTeamId}
              type={TYPE.LIVE}
              subtitle={name}
              subtitleColor={themeColor1}
              pageSize={1}
            />
          ))
        }
      </NationalTeams>
      <Competitions>
        {(competitions) =>
          competitions
            .filter((n) => n.hasLive)
            .map(({ id, name, themeColor1, matchPageSize }) => (
              <CompetitionMatches
                key={id}
                competitionId={id}
                type={TYPE.LIVE}
                subtitle={name}
                subtitleColor={themeColor1}
                pageSize={matchPageSize}
              />
            ))
        }
      </Competitions>
      <UpcomingMatchesSection title={LABELS.TITLE_UPCOMING} />
      <LeaderboardOrSquareAdvertisement uid="home-page-3" />
      <PastMatchesSection
        title={LABELS.TITLE_PAST}
        className={styles.pastMatches}
      />
      <LeaderboardOrSquareAdvertisement uid="home-page-1" />
      <h2>{LABELS.TITLE_NATIONAL_TEAM_PAST}</h2>
      <NationalTeams>
        {(nationalTeams) =>
          nationalTeams.map(({ name, themeColor1, nationalTeamId }) => (
            <NationalTeamMatches
              key={nationalTeamId}
              nationalTeamId={nationalTeamId}
              type={TYPE.PAST}
              subtitle={name}
              subtitleColor={themeColor1}
              pageSize={1}
            />
          ))
        }
      </NationalTeams>
    </section>
    <aside className={styles.aside}>
      <HomeNewsSection title={LABELS.TITLE_NEWS} />
      <HomeHighlights title={LABELS.TITLE_HIGHLIGHTS} />
      <BannerAdvertisement uid="home-page-2" />
      <CompetitionTableSummary
        title={LABELS.TITLE_STANDINGS}
        competitionId={competitionId}
      />
    </aside>
  </div>
);

export default HomePage;
