import * as React from "react";
import { useLocation } from "react-router-dom";

import { U14_COMPETITIONS } from "../../containers/competition/constants";
import { TYPE } from "../../containers/competitionMatches/constants";
import CompetitionResults from "../../containers/competitionResults";
import CompetitionTable from "../../containers/competitionTable";
import LeaderboardOrSquareAdvertisement from "../leaderboardOrSquareAdvertisement";
import styles from "./critical.scss";

const PAGINATION_QUERY_STRING_PARAM_NAME = "gameWeek";

const LABELS = {
  PAST_FIXTURES: "Past Fixtures",
};

const TeamTablePage = ({ teamId, competitionId, gameWeek }) => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  let gameWeekParam = searchParams.get(PAGINATION_QUERY_STRING_PARAM_NAME);

  if (gameWeekParam && !Number.isNaN(gameWeekParam)) {
    gameWeekParam = Number(gameWeekParam);
  } else {
    gameWeekParam = gameWeek;
  }

  return (
    <section className={styles.section}>
      {U14_COMPETITIONS.includes(competitionId.toString()) ? (
        <span className={styles.disclaimer}>
          The U14 leagues are development competitions in which no official
          classification is recorded.
        </span>
      ) : (
        <CompetitionTable
          teamId={teamId}
          competitionId={competitionId}
          isCurrent
        />
      )}
      <LeaderboardOrSquareAdvertisement
        uid="team-table-page-1"
        competitionId={competitionId}
      />
      <CompetitionResults
        title={LABELS.PAST_FIXTURES}
        competitionId={competitionId}
        type={TYPE.PAST}
        showPagination
        paginationQueryStringParamName={PAGINATION_QUERY_STRING_PARAM_NAME}
        gameWeek={Number(gameWeekParam)}
        maxGameWeek={Number(gameWeek)}
        teamId={teamId}
      />
    </section>
  );
};

export default TeamTablePage;
